import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import MobileMenuIcon from './MobileMenu/MobileMenuIcon';
import MobileMenuContainer from './MobileMenu/MobileMenuContainer';
import Logo from './Logo/Logo';
import Wordmark from '../../../images/assets/Wordmark.svg';
import device from '../../Common/Device';
import Link from '../../Common/Link';
import Nav from './Nav/Nav';
import pages from '../../../utils/pages';
import getElementPosition from '../../../utils/getElementPosition';
import {
  clearScrollPosition,
  saveScrollPosition,
  shrinkLogo
} from '../../../state/actions/pageActions';
import {
  toggleMobileMenu as toggleMobileMenuAction,
  closeAllMenus as closeAllMenusAction
} from '../../../state/actions/menuActions';

const FixedHeaderContainer = styled.div`
  width: 100%;
  height: 70px;
  background-color: #f7f7f7;
  z-index: 1000;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;

  + div {
    margin-top: 70px;
  }
`;

const HeaderContent = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-left: 154px;
  height: 70px;
  padding: 26px 0 20px;

  @media ${device.max.sm} {
    margin: 0;

    b {
      display: none;
    }
  }
`;

const WordmarkLink = styled(Link)`
  display: none;

  @media ${device.max.sm} {
    display: block;
    margin: 0 auto;
    text-align: center;
    position: relative;
    width: 98px;
    top: 24px;
  }
`;

const StyledLogo = styled(Logo)`
  position: absolute;
  top: 0;
  z-index: 1001;

  @media ${device.max.sm} {
    display: none;
  }
`;

const StyledCol = styled(Col)`
  @media ${device.max.sm} {
    display: none;
  }
`;

const MobileMenu = styled.div`
  @media ${device.min.md} {
    display: none;
  }
`;

export default ({ currentPage }) => {
  const logoShrink = useSelector(state => state.page.shrinkLogo);
  const mobileMenuOpen = useSelector(state => state.menu.mobileMenu.open);
  const scrollPosition = useSelector(state => state.page.scrollPosition);
  const mobileMenusOpen = useSelector(state => state.menu.mobileMenusOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    const handler = () => {
      const headerPos = getElementPosition('#header');
      if (!logoShrink && window.pageYOffset >= headerPos.bottom) {
        dispatch(shrinkLogo());
      } else if (logoShrink && window.pageYOffset <= headerPos.bottom) {
        dispatch(shrinkLogo());
      }
    };
    window.addEventListener('scroll', handler);

    return () => {
      window.removeEventListener('scroll', handler);
    };
  });

  useEffect(() => {
    if (scrollPosition > 0 && !mobileMenuOpen) {
      window.scrollTo(0, scrollPosition);
      dispatch(clearScrollPosition());
    }
  });

  const toggleMobileMenu = () => {
    dispatch(toggleMobileMenuAction());
    dispatch(saveScrollPosition(scrollPosition || window.pageYOffset));
  };

  const closeAllMenus = () => {
    dispatch(closeAllMenusAction());
  };

  return (
    <>
      <FixedHeaderContainer id="header">
        <div className="container-lg">
          <Row>
            <Col lg={9} md={8} xs={12}>
              <MobileMenu>
                <MobileMenuIcon
                  mobileMenusOpen={mobileMenusOpen}
                  onClick={!mobileMenusOpen ? toggleMobileMenu : closeAllMenus}
                />
                <MobileMenuContainer
                  menuOpen={mobileMenuOpen}
                  onClick={closeAllMenus}>
                  <Nav pages={pages} currentPage={currentPage} isVertical />
                </MobileMenuContainer>
              </MobileMenu>
              <StyledLogo />
              <WordmarkLink to="https://www.vancity.com/">
                <Wordmark />
              </WordmarkLink>
              <HeaderContent>
                <b>CommUNITY Centre</b>
              </HeaderContent>
            </Col>
            <StyledCol lg={3} md={4}>
              <Nav pages={pages} currentPage={currentPage} />
            </StyledCol>
          </Row>
        </div>
      </FixedHeaderContainer>
    </>
  );
};
