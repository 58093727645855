import React from 'react';
import styled from 'styled-components';
import { IoMdMenu, IoMdClose } from 'react-icons/io';
import device from '../../../Common/Device';

const MenuIcon = styled.div`
  display: none;

  @media ${device.max.sm} {
    display: block;
    position: absolute;
    top: 20px;
    cursor: pointer;
  }
`;

const StyledIoMdMenu = styled(IoMdMenu)`
  width: 2em;
  height: 2em;
  color: #ca1905;
`;

const StyledIoMdClose = styled(IoMdClose)`
  width: 2em;
  height: 2em;
  color: #ca1905;
`;

const MobileMenuIcon = ({ className, mobileMenusOpen, onClick }) => {
  return (
    <MenuIcon className={className} onClick={onClick}>
      {mobileMenusOpen ? <StyledIoMdClose /> : <StyledIoMdMenu />}
    </MenuIcon>
  );
};

export default MobileMenuIcon;
