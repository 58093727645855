import React, { useState } from 'react';
import styled from 'styled-components';
import { Nav } from 'reactstrap';
import NavItem from './NavItem';

const StyledNav = styled(Nav)`
  margin: ${props => (props.isVertical ? '0' : '0 auto')};
  justify-content: ${props => (props.isVertical ? '' : 'center')};
  flex-direction: ${props => (props.isVertical ? 'column' : 'row')};
  padding: 0;
  flex-wrap: nowrap;
  height: 70px;
`;

export default ({ pages, currentPage, isVertical = false }) => {
  const [current, setCurrent] = useState(currentPage);

  return (
    <StyledNav isVertical={isVertical}>
      {pages.map((item, index) => (
        <NavItem
          id={index}
          name={item.name}
          url={item.path}
          isActive={current === item.name}
          onClick={() => setCurrent(item.name)}
          isVertical={isVertical}
        />
      ))}
    </StyledNav>
  );
};
