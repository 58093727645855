import React from 'react';
import styled from 'styled-components';
import { NavItem, NavLink } from 'reactstrap';
import Link from '../../../Common/Link';

const StyledNavLink = styled(NavLink)`
  text-align: center;
  height: 100%;
  min-width: 100px;
  display: block;

  ${props =>
    props.isVertical
      ? `
  padding: 16px 24px;
  color: #000 !important;
  border: 1px solid #f1f1f1;
  text-align: left;
  `
      : `
  margin: 0 10px;
  padding: 26px 20px 20px 20px;
  color: #776e64;
  `}

  &:hover {
    color: #000;
    text-decoration: none;
    ${props =>
      !props.isVertical
        ? `
    border-bottom: 4px solid #ca1905;
    font-weight: bold;
    `
        : ``}
  }
`;

const StyledNavItem = styled(NavItem)`
  margin: 0;

  a {
    ${props =>
      props.isVertical && props.isActive
        ? `
            border-left: 4px solid #ca1905;
            background: #f1f1f1;`
        : ``}
    ${props =>
      !props.isVertical && props.isActive
        ? `
            border-bottom: 4px solid #ca1905;
            font-weight: bold;
            color: #000 !important;`
        : ``}
  }
`;

export default ({ id, url, name, isVertical, isActive, onClick }) => {
  return (
    <StyledNavItem key={id} isActive={isActive} isVertical={isVertical}>
      <StyledNavLink
        as={Link}
        to={url}
        onClick={onClick}
        isVertical={isVertical}>
        {name}
      </StyledNavLink>
    </StyledNavItem>
  );
};
