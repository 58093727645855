import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import OutlineButton from '../Common/Buttons/OutlineLinkButton';
import Link from '../Common/Link';
import CookieImage from '../../images/assets/cookie.svg';
import device from '../Common/Device';
import { hideCookieFab } from '../../state/actions/cookieActions';
import { setCookie } from '../../utils/Cookie';

const CookieFab = styled.div`
  align-self: stretch;
  width: 100%;
  padding: 35px 0;
  box-sizing: border-box;
  z-index: 20000;
  background: #f7f7f7;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`;

const LeftContent = styled.div`
  order: 1;
  flex-basis: 5%;
  align-self: center;

  @media ${device.max.sm} {
    flex-basis: 30%;
  }
`;

const MiddleContent = styled.div`
  order: 2;
  flex-basis: 80%;
  text-align: left;
  align-self: center;
  padding: 0 1.5rem;

  @media ${device.only.lg} {
    flex-basis: 69%;
  }

  @media ${device.only.md} {
    flex-basis: 65%;
  }

  @media ${device.max.sm} {
    flex-basis: 70%;
    padding: 0;
  }
`;

const RightContent = styled.div`
  order: 3;
  flex-basis: 5%;
  align-self: center;

  @media ${device.max.sm} {
    margin-top: 16px;
    margin-left: 0;
    flex-basis: 100%;
  }
`;

const TextStyle = styled.span`
  color: #000000;
  text-align: left;
`;

const AcceptButton = styled(OutlineButton)`
  width: 125px;

  @media ${device.max.md} {
    width: 100%;
  }
`;

const CookieFabImage = styled(CookieImage)`
  height: 80px;

  @media ${device.max.md} {
    height: 65px;
    width: auto;
  }
`;

export default () => {
  const dispatch = useDispatch();

  // Setting cookie values : cookieName - cookieNotification; cookieValue - 1; expDays - 100
  const storeCookie = event => {
    event.preventDefault();
    setCookie('cookieNotification', 1, 100);
    dispatch(hideCookieFab());
  };

  return (
    <CookieFab>
      <div className="container-lg">
        <ContentContainer>
          <LeftContent>
            <CookieFabImage />
          </LeftContent>
          <MiddleContent>
            <TextStyle>
              Our site uses cookies to provide you with an experience unique to
              you. By using vancity.com, you accept our{' '}
              <Link to="https://www.vancity.com/PrivacyAndSecurity/YourPrivacy/UnderstandingCookies/">
                use of cookies
              </Link>
              .
            </TextStyle>
          </MiddleContent>
          <RightContent>
            <AcceptButton to="/" onClick={storeCookie}>
              Accept
            </AcceptButton>
          </RightContent>
        </ContentContainer>
      </div>
    </CookieFab>
  );
};
