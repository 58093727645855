import React from 'react';
import styled from 'styled-components';
import Header from './Header';

const SectionHeader = styled.h2`
  margin: 0;
`;

export default ({ className, header, subheader, align = 'center' }) => (
  <Header className={className} subheader={subheader} align={align}>
    <SectionHeader>{header}</SectionHeader>
  </Header>
);
