import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Footer } from '@vancity/react-component-library';
import CookieFab from '../CookieFab/CookieFab';
import Header from './Header/Header';
import GlobalStyles from './GlobalStyles';

const Container = styled.div`
  position: relative;
  position: ${props => (props.mobileMenusOpen ? `fixed` : `static`)};
  width: 100%;
  top: ${props => `${-props.scrollPosition}px`};
`;

const Layout = ({ children, containsNavigation = false, currentPage }) => {
  const cookieFabVisible = useSelector(
    state => state.cookieFab.cookieFabVisible
  );
  const mobileMenusOpen = useSelector(state => state.menu.mobileMenusOpen);
  const scrollPosition = useSelector(state => state.page.scrollPosition);

  return (
    <>
      <Container
        mobileMenusOpen={mobileMenusOpen}
        scrollPosition={scrollPosition}>
        <Header currentPage={currentPage} />
        {children}
        <Footer containsNavigation={containsNavigation} />
        <GlobalStyles />
      </Container>
      {cookieFabVisible && <CookieFab />}
    </>
  );
};

export default Layout;
