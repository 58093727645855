import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import RedHeadingDeco from './RedHeadingDeco';
import Subheader from './Subheader';

const Container = styled.div`
  margin-bottom: 40px;
  text-align: ${props => props.align};
`;

const StyledSubheader = styled(Subheader)`
  margin: 16px 0 0 0;
`;

export default ({ className, subheader, align = 'center', children }) => (
  <Container className={className} align={align}>
    <Row>
      <Col xs={12}>
        {children}
        <RedHeadingDeco />
      </Col>
    </Row>
    {subheader && (
      <Row>
        <Col
          xs={12}
          md={align === 'center' ? 8 : 12}
          lg={align === 'center' ? 6 : 12}
          className="mx-auto">
          <StyledSubheader>{subheader}</StyledSubheader>
        </Col>
      </Row>
    )}
  </Container>
);
