import React from 'react';
import styled from 'styled-components';
import device from '../Device';
import scale from '../../../utils/scale';

const Subheader = styled.p`
  p {
    font-size: ${scale.desktop(1)}rem;
    line-height: 1.5;
    margin: 0;
    padding: 0;
  }

  font-size: ${scale.desktop(1)}rem;
  line-height: 1.5;
  margin: 0;
  padding: 0;

  @media ${device.max.md} {
    font-size: ${scale.mobile(1)}rem;

    p {
      font-size: ${scale.mobile(1)}rem;
    }
  }
`;

export default ({ className, children }) => {
  return <Subheader className={className}>{children}</Subheader>;
};
