import styled from 'styled-components';
import HeadingDeco from '../../../images/assets/heading_end_deco.svg';

const StyledHeadingDeco = styled(HeadingDeco)`
  g {
    g {
      fill: #ca1905;
    }
  }
`;

export default StyledHeadingDeco;
