import React from 'react';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';
import Wordmark from '../../../../images/assets/Wordmark.svg';
import device from '../../../Common/Device';

const Container = styled.div`
  > div {
    top: 0;
  }
`;

const MobileMenu = styled.div`
  display: none;

  @media ${device.max.md} {
    flex-direction: column;
    background-color: #f7f7f7;
    position: fixed;
    width: 90%;
    height: 100%;
    z-index: 1010;
    overflow: auto;
    display: flex;
    ${props => (!props.menuOpen ? `left: calc(-100% - 5px)` : `left: 0`)};
    transition: left 300ms ease-out;
  }
`;

const MobileMenuOverlay = styled.div`
  display: none;

  @media ${device.max.md} {
    position: fixed;
    background: #000;
    width: 100%;
    height: 100%;
    z-index: 1009;
    display: flex;
    visibility: ${props => (props.menuOpen ? 'visible' : 'hidden')};
    opacity: ${props => (props.menuOpen ? '0.5' : '0')};
    transition: visibility 0s, opacity 0.1s;
  }
`;

const Header = styled.div`
  padding: 16px 12px;
  border-bottom: thin solid #e5e5e5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CloseIcon = styled(IoMdClose)`
  width: 24px;
  height: 24px;
  color: #ca1905;
  cursor: pointer;
`;

const MobileMenuContainer = ({
  className,
  menuOpen,
  onClose,
  children,
  ...props
}) => {
  return (
    <Container className={className} {...props}>
      <MobileMenuOverlay onClick={onClose} menuOpen={menuOpen} />
      <MobileMenu menuOpen={menuOpen}>
        <Header>
          <Wordmark />
          <CloseIcon onClick={onClose} />
        </Header>
        {/* Children can be any Menu types */}
        {children}
      </MobileMenu>
    </Container>
  );
};

export default MobileMenuContainer;
