import React from 'react';
import styled from 'styled-components';
import Button from './Button';

const OutlineButton = styled(Button)`
  background: none;
  box-sizing: border-box;
  text-decoration: none !important;

  ${props =>
    props.buttonColor === 'white'
      ? `
  color: #ffffff;
  box-shadow: inset 0 0 0 1px #ffffff;

  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 2px #ffffff;
    color: #ffffff;
    text-decoration: none;
    background: none;
  }

  &:active {
    background-color: #ffffff;
    color: #000;
    box-shadow: none;
  }

  &:disabled {
    background-color: #ffffff;
    color: #ffffff;
  }

  svg {
    color: #ffffff;
  }
  `
      : `
      color: #ca1905;
      box-shadow: inset 0 0 0 1px #ca1905;

      &:hover,
      &:focus {
        box-shadow: inset 0 0 0 2px #a91504;
        color: #a91504;
        text-decoration: none;
        background: none;
      }

      &:active {
        background-color: #a91504;
        color: #ffffff;
        box-shadow: none;
      }

      &:disabled {
        box-shadow: inset 0 0 0 1px #e5e5e5;
        background-color: #f7f7f7;
        color: #776e64;
      }

      svg {
        color: #ca1905;
      }
      `};
`;

export default ({ buttonColor = 'red', children, ...props }) => (
  <OutlineButton buttonColor={buttonColor} {...props}>
    {children}
  </OutlineButton>
);
