import React from 'react';
import styled from 'styled-components';
import device from './Device';

const StyledSection = styled.section`
  position: relative;
  transform: skewY(2deg);
  width: 100%;
  height: 100%;
  padding: 100px 0;

  &:first-child {
    ::before {
      width: 100%;
      height: 100px;
      background: inherit;
      position: absolute;
      top: 0;
      content: '';
      display: block;
      transform: skewY(-2deg);
    }
  }

  &:last-child {
    ::after {
      width: 100%;
      height: 100px;
      background: inherit;
      position: absolute;
      bottom: 0;
      content: '';
      display: block;
      transform: skewY(-2deg);
    }
  }

  > div {
    transform: skewY(-2deg);
  }

  @media ${device.max.md} {
    padding: 60px 0;
  }
`;

export default ({ children, id, className }) => {
  return (
    <StyledSection id={id} className={className}>
      <div>{children}</div>
    </StyledSection>
  );
};
