export default [
  {
    name: 'Home',
    path: '/'
  },
  {
    name: 'Podcast',
    path: '/podcast'
  }
];
