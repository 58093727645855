import React from 'react';
import styled from 'styled-components';

const Sections = styled.div`
  position: relative;
  z-index: ${props => (props.containsNavigation ? 1005 : 0)};

  > section {
    :nth-child(odd) {
      background: ${props => props.oddBackground};
    }

    :nth-child(even) {
      background: ${props => props.evenBackground};
    }
  }
`;

export default ({
  children,
  className,
  oddBackground = '#F7F7F7',
  evenBackground = '#fff',
  containsNavigation = false
}) => {
  return (
    <Sections
      className={className}
      oddBackground={oddBackground}
      evenBackground={evenBackground}
      containsNavigation={containsNavigation}>
      {children}
    </Sections>
  );
};
