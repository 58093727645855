import React from 'react';
import { useSelector } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';
import Link from '../../../Common/Link';

const Logo = styled(Link)`
  width: 130px;
  transform: scale(${props => (props.shrink ? '0.85' : '1')});
  margin-top: -5.6px;
  transition: transform 100ms linear;
`;

export default ({ className }) => {
  const shrink = useSelector(state => state.page.shrinkLogo);

  const vancityLogo = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "VancityLogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 158) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Logo className={className} to="https://www.vancity.com/" shrink={shrink}>
      <Img
        fluid={vancityLogo.placeholderImage.childImageSharp.fluid}
        fadeIn={false}
        loading="eager"
      />
    </Logo>
  );
};
